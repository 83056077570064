var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"p-0",attrs:{"fluid":""}},[_c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("@/assets/images/background/banner.png"),"alt":""}}),_c('b-container',[_c('div',{staticClass:"page-title"},[_vm._v(_vm._s(_vm.$t('announcements.title')))]),_c('div',{staticClass:"page-subtile"},[_vm._v(" "+_vm._s(_vm.$t('announcements.content'))+" ")])])],1),_c('b-container',[_c('div',{staticClass:"list-news-2"},[(_vm.list.length <= 0)?_c('div',{staticClass:"text-center d-flex h-100 align-items-center justify-content-center",staticStyle:{"min-height":"300px","font-size":"25px","font-weight":"600"}},[_vm._v(" "+_vm._s(_vm.$t('announcements.not_found'))+" ")]):_vm._e(),_vm._l((_vm.list),function(blog,index){return _c('router-link',{key:("list-2-34-" + index),staticClass:"news-box-2",attrs:{"to":{
                    name: 'Detail',
                    params: {
                        type: blog.blog_slug,
                        id: blog.article_slug,
                        locale: _vm.$i18n.locale,
                    },
                }}},[_c('div',{staticClass:"info"},[_c('span',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.getDateTime(blog.created_at, blog.article_lang))+" ")]),_c('h3',[_vm._v(_vm._s(blog.article_title))])]),_c('div',{staticClass:"thumbnail"},[_c('img',{attrs:{"src":blog.article_thumbnail,"alt":""}})])])}),(_vm.list.length && _vm.page < _vm.totalPages)?_c('infinite-loading',{attrs:{"spinner":"spiral"},on:{"infinite":_vm.infiniteScroll}}):_vm._e()],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }